// app/javascript/components/MenuComponent.jsx
import React from 'react';
import { Menu } from 'antd';
import AppConfigProvider from 'components/Layout/AppConfigProvider';
import { harvestManualWorksPath, timesheetsManualWorksPath } from 'js-routes';
import { i18n } from 'helpers/i18n';

const { Item } = Menu;

const MenuSidebar = ({ controllerName }) => {
  const getActiveKey = () => {
    if (controllerName === 'harvest') return 'harvest';
    if (controllerName === 'payroll') return 'payroll';
    if (controllerName === 'timesheets') return 'timesheets';

    return 'overview';
  };

  return (
    <AppConfigProvider>
      <Menu mode="inline" style={{ width: 256 }} selectedKeys={[getActiveKey()]}>
        <Item key="harvest">
          <a href={harvestManualWorksPath()}>{i18n.t('harvest.index.title')}</a>
        </Item>
        <Item key="payroll">
          <a href="#">{i18n.t('payroll.index.title')}</a>
        </Item>
        <Item key="timesheets">
          <a href={timesheetsManualWorksPath()}>{i18n.t('timesheet.index.title')}</a>
        </Item>
      </Menu>
    </AppConfigProvider>
  );
};

export default MenuSidebar;
