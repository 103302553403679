import React, { useState } from 'react';
import { arrayOf, number, string } from 'prop-types';
import { Typography, Row, Space, Button, Popconfirm, Descriptions, Image } from 'antd';
import Layout from 'components/Layout';
import { omit } from 'lodash';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { scoutingReportsPath, scoutingReportPath, editScoutingReportPath } from 'js-routes';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';
import { setSuccessNotification, setErrorNotification } from 'hooks/useNotification';

const ScoutingReport = (props) => {
  const { id, photosUrls } = props;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const itemsWithoutPhotos = Object.entries(omit(props, 'photosUrls')).map(([key, value]) => (
    { label: key, children: value }
  ));

  const itemsPhotos = {
    label: 'photos',
    children: (
      <Image.PreviewGroup>
        {photosUrls.map((photoUrl) => <Image key={photoUrl} width={200} src={photoUrl} />)}
      </Image.PreviewGroup>
    ),
  };

  const removeReportRequest = async (id) => {
    setDeleteLoading(true);
    try {
      await fetchData({
        url: scoutingReportPath(id),
        method: 'DELETE',
      });
      setSuccessNotification(i18n.t('scouting_report.notifications.deleted_successfully'));
      window.location.href = scoutingReportsPath();
    } catch (error) {
      setErrorNotification(i18n.t('scouting_report.notifications.delete_failed'));
    } finally {
      // setDeleteLoading(false);
    }
  };

  const handleEditClick = async () => {
    setEditLoading(true);
    try {
      window.location.href = editScoutingReportPath(id);
    } finally {
      // setEditLoading(false);
    }
  };

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Typography.Title>{`${i18n.t('scouting_report.scouting_report')} #${id}`}</Typography.Title>

        <Space style={{ marginTop: 11 }}>
          <Button
            onClick={handleEditClick}
            loading={editLoading}
            disabled={deleteLoading || editLoading}
            icon={<EditOutlined />}
          >
            {i18n.t('edit')}
          </Button>
          <Popconfirm
            title={i18n.t('scouting_report.delete_popup_title')}
            description={i18n.t('scouting_report.delete_popup_description')}
            disabled={deleteLoading || editLoading}
            onConfirm={() => removeReportRequest(id)}
          >
            <Button
              type="primary"
              loading={deleteLoading}
              icon={<DeleteOutlined />}
              danger
            >
              {i18n.t('delete')}
            </Button>
          </Popconfirm>
        </Space>
      </Row>

      <Descriptions
        items={[...itemsWithoutPhotos, itemsPhotos]}
        column={1}
        contentStyle={{ display: 'block' }}
        bordered
      />
    </Layout>
  );
};

ScoutingReport.propTypes = {
  id: number.isRequired,
  photosUrls: arrayOf(string).isRequired,
};

ScoutingReport.defaultProps = {
};

export default ScoutingReport;
