import React from 'react';
import { staffIndexPath } from 'js-routes';
import Layout from 'components/Layout';
import { css } from '@emotion/css';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';
import { setSuccessNotification, setErrorNotification } from 'hooks/useNotification';
import UserForm from './UserForm';

const mainStyles = css`
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const CreateUser = () => {
  const handleCreate = async (values) => {
    try {
      const response = await fetchData({
        url: staffIndexPath(),
        method: 'POST',
        body: { user: values },
        contentType: 'json',
        responseType: 'json',
      });

      if (!response.errors?.length) {
        setSuccessNotification(i18n.t('staff.index.new'));
        window.location.href = staffIndexPath();
      } else {
        setErrorNotification(i18n.t('staff.errors.invalid'));
      }
    } catch (error) {
      setErrorNotification(i18n.t('staff.errors.invalid'));
      window.location.href = staffIndexPath();
    }
  };

  return (
    <Layout className={mainStyles}>
      <h1>{i18n.t('staff.new.title')}</h1>
      <UserForm
        onSubmit={handleCreate}
        onCancel={() => { window.location.href = staffIndexPath(); }}
      />
    </Layout>
  );
};

export default CreateUser;
