import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Select, Button, Spin, message, Checkbox, Modal, Tag } from 'antd';
import Layout from 'components/Layout';
import { fetchData } from 'helpers/fetchData';
import { tablePreferencePath, timesheetsSummaryManualWorksPath } from 'js-routes';
import { i18n } from 'helpers/i18n';
import {
  filterContainerStyles,
  mainStyles,
  scrollableContainerStyles,
  loadingContainer,
} from './styles';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Timesheets = ({ orchards }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedOrchard, setSelectedOrchard] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const controller = new AbortController();

  // Fetch Table Preferences
  const fetchTablePreferences = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData({
        url: tablePreferencePath({ table_name: 'timesheet' }),
        method: 'GET',
      });

      const sortedColumns = response.preferences.sort((a, b) => a.order - b.order);

      setAllColumns(sortedColumns);
      setFilteredColumns(sortedColumns.filter((col) => col.visible));
    } catch (error) {
      message.error('Failed to fetch table preferences.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateTablePreferences = async (updatedPreferences) => {
    try {
      const response = await fetchData({
        url: tablePreferencePath({ table_name: 'timesheet' }),
        method: 'PUT',
        body: { preferences: updatedPreferences },
        contentType: 'json',
      });

      setAllColumns(response.preferences);
      setFilteredColumns(response.preferences.filter((col) => col.visible));
      message.success('Preferences updated successfully!');
    } catch (error) {
      message.error('Failed to update preferences.');
    }
  };

  // Fetch filtered data
  const fetchFilteredData = async () => {
    setIsLoading(true);
    try {
      const params = {
        start_date: selectedDateRange?.[0]?.format('DD-MM-YYYY'),
        end_date: selectedDateRange?.[1]?.format('DD-MM-YYYY'),
        orchard_ids: [selectedOrchard],
        quarter_ids: [selectedQuarter],
      };

      const url = timesheetsSummaryManualWorksPath({ ...params });

      const response = await fetchData({
        url,
        method: 'GET',
        headers: {},
        contentType: 'json',
        responseType: 'json',
      });

      setQuarterOptions(response.filterOptions.quarters);
      setFilteredData(response.timesheets);
    } catch (error) {
      message.error('Failed to fetch data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTablePreferences();
  }, []);

  useEffect(() => {
    fetchFilteredData();
    return () => controller.abort();
  }, [selectedDateRange, selectedOrchard, selectedQuarter]);

  // Filter handlers
  const handleDateChange = (dates) => setSelectedDateRange(dates);
  const handleOrchardChange = (value) => {
    setSelectedOrchard(value);
    setSelectedQuarter(null);
  };
  const handleQuarterChange = (value) => setSelectedQuarter(value);
  const resetFilters = () => {
    setSelectedDateRange(null);
    setSelectedOrchard(null);
    setSelectedQuarter(null);
  };

  const toggleColumnVisibility = (key) => {
    const updatedColumns = allColumns.map((col) => (
      col.name === key ? { ...col, visible: !col.visible } : col
    ));

    updateTablePreferences(updatedColumns);
  };

  const saveSettings = () => {
    setIsSettingsModalOpen(false);
  };

  const mainTableColumns = filteredColumns.map((col) => {
    const columnConfig = {
      title: col.displayName,
      dataIndex: col.columnName,
      key: col.name,
    };

    if (col.columnName === 'date') {
      columnConfig.width = 120;
      columnConfig.render = (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
      );
    }

    if (col.columnName === 'status') {
      columnConfig.render = (text) => {
        let color = 'default';
        if (text === 'completed') color = 'green';
        else if (text === 'in_progress') color = 'blue';
        else if (text === 'pending') color = 'orange';
        return <Tag color={color}>{i18n.t(text)}</Tag>;
      };
    }

    return columnConfig;
  });

  const settingsColumns = [
    {
      title: i18n.t('order'),
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: i18n.t('column_name'),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: i18n.t('visible'),
      key: 'visible',
      render: (_, record) => (
        <Checkbox
          checked={record.visible}
          onChange={() => toggleColumnVisibility(record.name)}
        />
      ),
    },
  ];

  return (
    <Layout className={mainStyles} fullWidth>
      <h1>{i18n.t('timesheet.index.title')}</h1>
      <div className={filterContainerStyles}>
        <RangePicker
          onChange={handleDateChange}
          value={selectedDateRange}
          format="DD-MM-YYYY"
        />
        <Select
          placeholder={`${i18n.t('all')} ${i18n.t('harvest.index.orchards')}`}
          onChange={handleOrchardChange}
          value={selectedOrchard}
          style={{ width: 200 }}
        >
          {orchards.map((orchard) => (
            <Option key={orchard.id} value={orchard.id}>
              {orchard.name}
            </Option>
          ))}
        </Select>
        <Select
          placeholder={`${i18n.t('all')} ${i18n.t('quarters')}`}
          onChange={handleQuarterChange}
          value={selectedQuarter}
          style={{ width: 200 }}
          loading={isLoading}
        >
          {quarterOptions.map((quarter) => (
            <Option key={quarter.id} value={quarter.id}>
              {quarter.name}
            </Option>
          ))}
        </Select>
        <Button onClick={() => setIsSettingsModalOpen(true)}>
          {i18n.t('table_preferences')}
        </Button>
        <Button onClick={resetFilters}>{i18n.t('harvest.index.reset_filters')}</Button>
      </div>

      {isLoading ? (
        <div className={loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={scrollableContainerStyles}>
          <Table
            columns={mainTableColumns}
            dataSource={filteredData}
            rowKey={(record) => record.id}
          />
        </div>
      )}

      <Modal
        title={i18n.t('table_preferences')}
        visible={isSettingsModalOpen}
        onOk={saveSettings}
        onCancel={() => setIsSettingsModalOpen(false)}
      >
        <Table
          dataSource={allColumns}
          columns={settingsColumns}
          rowKey="name"
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      </Modal>
    </Layout>
  );
};

export default Timesheets;
