import { useEffect } from 'react';
import { message } from 'antd';

/**
 * Custom hook to handle notifications from localStorage
 */
const useNotification = () => {
  useEffect(() => {
    // Check for success notification
    const successMessage = localStorage.getItem('successNotification');
    if (successMessage) {
      message.success(successMessage);
      localStorage.removeItem('successNotification');
    }

    // Check for error notification
    const errorMessage = localStorage.getItem('errorNotification');
    if (errorMessage) {
      message.error(errorMessage);
      localStorage.removeItem('errorNotification');
    }
  }, []);
};

/**
 * Save a success notification to localStorage
 */
export const setSuccessNotification = (message) => {
  localStorage.setItem('successNotification', message);
};

/**
 * Save an error notification to localStorage
 */
export const setErrorNotification = (message) => {
  localStorage.setItem('errorNotification', message);
};

export default useNotification;
