import React from 'react';
import { Button, Space, Popconfirm } from 'antd';
import { staffPath, staffIndexPath } from 'js-routes';
import { DeleteOutlined } from '@ant-design/icons';
import Layout from 'components/Layout';
import { css } from '@emotion/css';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';
import { setSuccessNotification, setErrorNotification } from 'hooks/useNotification';
import UserForm from './UserForm';

const mainStyles = css`
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const EditUser = ({ user }) => {
  const handleUpdate = async (values) => {
    try {
      const response = await fetchData({
        url: staffPath(user.id),
        method: 'PUT',
        body: { user: values },
        contentType: 'json',
        responseType: 'json',
      });

      if (!response.errors?.length) {
        setSuccessNotification(i18n.t('staff.edit.success'));
        window.location.href = staffIndexPath();
      } else {
        setErrorNotification(i18n.t('staff.errors.invalid'));
        window.location.href = staffIndexPath();
      }
    } catch (error) {
      setErrorNotification(i18n.t('staff.errors.invalid'));
      window.location.href = staffIndexPath();
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetchData({
        url: staffPath(user.id),
        method: 'DELETE',
        contentType: 'json',
        responseType: 'json',
      });

      if (!response.errors?.length) {
        setSuccessNotification(i18n.t('staff.destroy.success'));
        window.location.href = staffIndexPath();
      } else {
        setErrorNotification(i18n.t('staff.errors.invalid'));
        window.location.href = staffIndexPath();
      }
    } catch (error) {
      setErrorNotification(i18n.t('staff.destroy.error'));
      window.location.href = staffIndexPath();
    }
  };

  return (
    <Layout className={mainStyles}>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h1>{i18n.t('staff.edit.title')}</h1>
        <Popconfirm
          title={i18n.t('staff.edit.delete_popup_title')}
          description={i18n.t('staff.edit.delete_popup_description')}
          onConfirm={handleDelete}
        >
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            danger
          >
            {i18n.t('delete')}
          </Button>
        </Popconfirm>
      </Space>
      <UserForm
        user={user}
        onSubmit={handleUpdate}
        onCancel={() => window.history.back()}
      />
    </Layout>
  );
};

export default EditUser;
