import { useState, useEffect } from 'react';
import { defaultMapColorType } from 'components/Map/components/MapColoringSwitcher';
import { colorizeFeatures } from '../helpers';

const useMapColorType = ({ mapInstance, features, mapStyle }) => {
  const [mapColorType, setMapColorType] = useState(defaultMapColorType);

  useEffect(() => {
    if (!mapInstance || !features || features.length === 0) return;

    if (mapInstance?.style?._loaded) {
      colorizeFeatures(features, mapInstance, mapColorType);
    } else {
      const onStyleLoadFeature = () => {
        colorizeFeatures(features, mapInstance, mapColorType);
        mapInstance.off('style.load', onStyleLoadFeature);
      };

      mapInstance.on('style.load', onStyleLoadFeature);
    }
  }, [mapColorType, mapInstance, features, mapStyle]);

  return { mapColorType, setMapColorType };
};

export { useMapColorType };

