import { css } from '@emotion/css';

const mainStyles = css`
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const loadingContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const scrollableContainerStyles = css`
  display: flex;
  overflow-x: auto;
  display: block;
  // width: calc(100vw - 256px - 40px - 40px - 20px);
`;

const filterContainerStyles = css`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

export {
  filterContainerStyles,
  mainStyles,
  scrollableContainerStyles,
  loadingContainer,
};
