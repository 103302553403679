import React from 'react';
import { Table, Button, Space, Statistic } from 'antd';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import Layout from 'components/Layout';
import { i18n } from 'helpers/i18n';
import { editStaffPath, newStaffPath } from 'js-routes';
import useNotification from 'hooks/useNotification';

const mainStyles = css`
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const UsersTable = ({ users }) => {
  useNotification();

  const formattedUsers = users.map((user) => ({
    id: user.id,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    role: user.role ? i18n.t(`staff.attributes.${user.role}`) : '',
    company: { name: user.companyName },
    supervisorId: user.supervisorId,
    workerId: user.workerId,
  }));

  const columns = [
    { title: i18n.t('staff.attributes.first_name'), dataIndex: 'first_name', key: 'first_name' },
    { title: i18n.t('staff.attributes.last_name'), dataIndex: 'last_name', key: 'last_name' },
    { title: i18n.t('staff.attributes.email'), dataIndex: 'email', key: 'email' },
    { title: i18n.t('staff.attributes.position'), dataIndex: 'role', key: 'role' },
    {
      title: i18n.t('staff.actions.action'),
      key: 'action',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => { window.location.href = editStaffPath(record.id); }}
        >
          {i18n.t('staff.actions.edit')}
        </Button>
      ),
    },
  ];

  return (
    <Layout className={mainStyles} fullWidth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h1>{i18n.t('staff.index.title')}</h1>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '30px',
          }}
        >
          <Statistic
            title={i18n.t('staff.index.total')}
            value={formattedUsers?.length || 0}
            prefix={<TeamOutlined />}
            style={{ textAlign: 'right' }}
          />
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => { window.location.href = newStaffPath(); }}
          >
            {i18n.t('staff.index.new')}
          </Button>
        </Space>
      </div>
      <Table
        dataSource={formattedUsers}
        columns={columns}
        rowKey={(record) => record.id}
      />
    </Layout>
  );
};

export default UsersTable;
