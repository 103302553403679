import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, number, shape } from 'prop-types';
import { newScoutingReportPath } from 'js-routes';
import { Row, Button, Table, Space, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Layout from 'components/Layout';
import Map from 'components/Map';
import useBreakpoints from 'hooks/useBreakpoints';
import { i18n } from 'helpers/i18n';
import maplibregl from 'maplibre-gl';
import { point, featureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';
import { useMapColorType } from 'components/Map/hooks/useMapColorType';
import useNotification from 'hooks/useNotification';
import { columns } from './columns';
import { tableStyles } from './styles';

const { Title } = Typography;

const getVisibleFeatures = ({ visibleRows, quartersFeatures, rowsFeatures, plantsFeatures }) => {
  const visibleQuarterIds = visibleRows.map(({ quarterId }) => quarterId);
  const visibleQuarterRowsIds = visibleRows.map(({ id }) => id);
  const visibleQuartersFeatures = (
    quartersFeatures.filter(({ properties }) => visibleQuarterIds.includes(properties.id))
  );
  const visibleRowsFeatures = (
    rowsFeatures.filter(({ properties }) => visibleQuarterRowsIds.includes(properties.id))
  );

  return [...visibleQuartersFeatures, ...visibleRowsFeatures, ...plantsFeatures];
};

const ScoutingReports = ({ visibleRows, scoutingReports, geoJsonLayers }) => {
  const mapRef = useRef(null);
  const [bounds, setBounds] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isXs } = useBreakpoints();
  const { quartersGeoJson, quarterRowsGeoJson, plantsGeoJson } = geoJsonLayers;
  const { features: quartersFeatures = [] } = quartersGeoJson || {};
  const { features: rowsFeatures = [] } = quarterRowsGeoJson || {};
  const { features: plantsFeatures = [] } = plantsGeoJson || {};
  const [mapStyle, setMapStyle] = useState();
  const { mapColorType, setMapColorType } = useMapColorType({
    mapInstance: mapRef.current,
    features: getVisibleFeatures({ visibleRows, quartersFeatures, rowsFeatures, plantsFeatures }),
    mapStyle,
  });

  const handleCreateClick = async () => {
    setLoading(true);
    try {
      window.location.href = newScoutingReportPath();
    } finally {
      // setEditLoading(false);
    }
  };

  useNotification();

  useEffect(() => {
    const coordinates = scoutingReports.map(({ location }) => location);
    const points = featureCollection(coordinates.map(point));

    if (points.features.length) setBounds(bbox(points));

    coordinates.forEach((coords) => {
      new maplibregl.Marker().setLngLat(coords).addTo(mapRef.current);
    });
  }, []);

  return (
    <Layout>
      <Spin spinning={loading}>
        <Row justify="space-between" align="middle">
          <Title level={isXs ? 2 : 1}>{i18n.t('scouting_reports')}</Title>

          <Button type="primary" onClick={handleCreateClick} style={{ marginTop: 11 }}>
            <PlusOutlined />
            {i18n.t('create')}
          </Button>
        </Row>

        <Space direction="vertical" style={{ width: '100%' }}>
          <Map
            mapRef={mapRef}
            bounds={bounds || (quartersGeoJson && bbox(quartersGeoJson))}
            geoJsonLayers={geoJsonLayers}
            mapColorType={mapColorType}
            setMapColorType={setMapColorType}
            setMapStyle={setMapStyle}
            isClickEnabled={false}
            isHoverEnabled
          />

          <Table
            className={tableStyles}
            columns={columns}
            dataSource={scoutingReports}
            size={isXs ? 'small' : 'middle'}
          />
        </Space>
      </Spin>
    </Layout>
  );
};

ScoutingReports.propTypes = {
  visibleRows: arrayOf(shape({
    key: number,
    quarterId: number,
  })).isRequired,
  scoutingReports: arrayOf(shape({})).isRequired,
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
    quarterRowsGeoJson: shape({}),
    plantsGeoJson: shape({}),
  }),
};

ScoutingReports.defaultProps = {
  geoJsonLayers: {},
};

export default ScoutingReports;
