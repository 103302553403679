import { userSessionPath } from 'js-routes';
import { decamelizeKeys } from 'humps';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';

const sendForm = (values) => fetchData({
  url: userSessionPath(),
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: { user: decamelizeKeys(values) },
})
  .then((response) => {
    if (!response.token) {
      return response.json().then(() => {
        throw new Error(i18n.t('login.error_message.invalid_email_or_password'));
      });
    }
    return response;
  });

export { sendForm };
