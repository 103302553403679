import { css } from '@emotion/css';

const quarterNameLocationStyles = css`
  position: absolute;
  bottom: 40px;
  right: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 1px 8px;

  .ant-typography {
    font-size: 13px;
  }
`;

const markerPositionStyles = css`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: max-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 7px 1px;
  cursor: pointer;

  .ant-typography {
    font-size: 11px;
  }
`;

const modalStyles = css`
  .ant-modal-content {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
`;

export { quarterNameLocationStyles, markerPositionStyles, modalStyles };
