import React, { useState } from 'react';
import { arrayOf, number, shape } from 'prop-types';
import { Form, Input, Upload, Modal, Button, Typography, Alert, Select, Spin } from 'antd';
import { gray } from '@ant-design/colors';
import { CameraOutlined, SaveOutlined } from '@ant-design/icons';
import { getData } from 'exif-js';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';
import useBreakpoints from 'hooks/useBreakpoints';
import { scoutingReportPath, scoutingReportsPath } from 'js-routes';
import Layout from 'components/Layout';
import { setSuccessNotification, setErrorNotification } from 'hooks/useNotification';
import ScoutingReportFormMap from './ScoutingReportFormMap';
import { modalStyles } from './styles';

const buildFile = async (url) => {
  const blob = await fetch(url).then((r) => r.blob());
  const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });

  return file;
};

const buildFormData = async ({
  scoutableId,
  scoutableType,
  userId,
  location,
  description,
  photos,
  assignedUsers: assignedUsersIds,
}) => {
  const formData = new FormData();
  formData.append('scouting_report[scoutable_id]', scoutableId);
  formData.append('scouting_report[scoutable_type]', scoutableType);
  formData.append('scouting_report[user_id]', userId);
  formData.append('scouting_report[location]', location);
  formData.append('scouting_report[description]', description);

  assignedUsersIds.forEach((id) => formData.append('scouting_report[user_ids][]', id));

  for await (const photo of photos) {
    const file = photo?.originFileObj || await buildFile(photo.url);

    getData(file, function () {
      const { exifdata } = this;

      console.log({ exifdata });
    });

    formData.append('scouting_report[photos][]', file);
  }

  return formData;
};

const buildFileList = (photosUrls) => photosUrls?.map((url, index) => ({
  uid: index,
  name: 'image.png',
  status: 'done',
  url,
})) || [];

const submitForm = ({ id, setErrors }) => async (params) => {
  const formData = await buildFormData(params);

  fetchData({
    url: id ? scoutingReportPath(id) : scoutingReportsPath(),
    method: id ? 'PUT' : 'POST',
    body: formData,
  }).then(({ errors }) => {
    if (errors) { setErrors(errors); return; }
    window.location.href = scoutingReportsPath();
  });
};

const getAlertDescription = (errors) => (
  Object.entries(errors).map(([attribute, errorMessage]) => `${attribute}: ${errorMessage}`)
);

const ScoutingReportForm = ({
  scoutingReport: { id, location, description, photosUrls, assignedUsers },
  geoJsonLayers,
  users,
}) => {
  const [form] = Form.useForm();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const breakpoints = useBreakpoints();
  const { isXs } = breakpoints;
  const action = id ? i18n.t('edit') : i18n.t('create');
  const name = id ? `#${id}` : '';

  const handlePreview = async (file) => {
    const reader = new FileReader();
    reader.onload = () => setImgUrl(reader.result);
    reader.readAsDataURL(file.originFileObj);

    setIsPreviewOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await submitForm({ id, setErrors })(values);
      setSuccessNotification(i18n.t('scouting_report.notifications.saved_successfully'));
    } catch (error) {
      setErrorNotification(i18n.t('scouting_report.notifications.save_failed'));
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Layout>
      {errors && (
        <Alert
          message={i18n.t('error')}
          description={getAlertDescription(errors)}
          type="error"
          onClose={() => setErrors(null)}
          style={{ marginTop: 20 }}
          showIcon
          closable
        />
      )}

      <Typography.Title level={isXs ? 2 : 1}>
        {`${action} ${i18n.t('scouting_report.scouting_report').toLocaleLowerCase()} ${name}`}
      </Typography.Title>

      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            description: description || '',
            assignedUsers: assignedUsers || [],
            photos: buildFileList(photosUrls),
          }}
        >
          <Form.Item
            name="location"
            label={i18n.t('activerecord.attributes.scouting_report.location')}
            initialValue={location}
            rules={[{ required: true }]}
          >
            <ScoutingReportFormMap
              form={form}
              location={location}
              geoJsonLayers={geoJsonLayers}
            />
          </Form.Item>

          <Form.Item name="userId" hidden><Input /></Form.Item>
          <Form.Item name="scoutableId" hidden><Input /></Form.Item>
          <Form.Item name="scoutableType" hidden><Input /></Form.Item>

          <Form.Item
            name="photos"
            label={i18n.t('photos')}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture-card"
              onPreview={handlePreview}
              multiple
            >
              <CameraOutlined style={{ fontSize: '40px', color: gray.primary }} />
            </Upload>
          </Form.Item>

          <Form.Item
            name="description"
            label={i18n.t('activerecord.attributes.scouting_report.description')}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="assignedUsers" label={i18n.t('scouting_report.assigned_users')}>
            <Select options={users} mode="multiple" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              <SaveOutlined />
              {i18n.t('save')}
            </Button>
          </Form.Item>

          <Modal
            className={modalStyles}
            open={isPreviewOpen}
            onCancel={() => setIsPreviewOpen(false)}
            footer={null}
          >
            <img src={imgUrl} style={{ height: 520 }} alt="" />
          </Modal>
        </Form>
      </Spin>
    </Layout>
  );
};

ScoutingReportForm.propTypes = {
  scoutingReport: shape({ id: number }),
  geoJsonLayers: shape({}),
  users: arrayOf(shape({})).isRequired,
};

ScoutingReportForm.defaultProps = {
  scoutingReport: {},
  geoJsonLayers: {},
};

export default ScoutingReportForm;
