const buildQuartersFillLayer = () => ({
  id: 'quartersGeoJson',
  type: 'fill',
  source: 'quartersGeoJson',
  paint: {
    'fill-color': ['coalesce', ['feature-state', 'color'], '#fff'], // #4c1f14
    'fill-opacity': ['coalesce', ['feature-state', 'opacity'], 0],
  },
});

const buildQuartersBordersLayer = () => ({
  id: 'quartersBorders',
  type: 'line',
  source: 'quartersGeoJson',
  paint: {
    'line-color': '#000',
    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1],
  },
});

const buildQuartersLabelsLayer = () => ({
  id: 'quartersGeoJsonLabels',
  type: 'symbol',
  source: 'quartersGeoJson',
  layout: {
    'text-field': ['get', 'name'],
    'text-size': 12,
    'text-anchor': 'center',
    'text-offset': [0, 0],
  },
  paint: {
    'text-color': '#000',
    'text-halo-color': '#fff',
    'text-halo-width': 1.2,
  },
  filter: ['has', 'centroid'],
});

const buildQuarterRowsFillLayer = () => ({
  id: 'quarterRowsGeoJson',
  type: 'fill',
  source: 'quarterRowsGeoJson',
  paint: {
    'fill-color': ['coalesce', ['feature-state', 'color'], '#fff'], // #369f4c
    'fill-opacity': ['coalesce', ['feature-state', 'opacity'], 0],
  },
});

const buildQuarterRowsBordersFillLayer = () => ({
  id: 'quarterRowsBorders',
  type: 'line',
  source: 'quarterRowsGeoJson',
  paint: {
    'line-color': '#000',
    'line-width': [
      'interpolate', ['linear'], ['zoom'],
      15, ['case', ['boolean', ['feature-state', 'hover'], false], 3, 0],
      22, ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1],
    ],
  },
});

const buildQuarterRowsLabelsLayer = () => ({
  id: 'quarterRowsGeoJsonLabels',
  type: 'symbol',
  source: 'quarterRowsGeoJson',
  layout: {
    'text-field': ['get', 'name'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#000',
    'text-halo-color': '#fff',
    'text-halo-width': 1.2,
  },
});

const buildPlantsFillLayer = () => ({
  id: 'plantsGeoJson',
  type: 'fill',
  source: 'plantsGeoJson',
  paint: {
    'fill-color': ['coalesce', ['feature-state', 'color'], '#fff'], // #4c1f14
    'fill-opacity': ['coalesce', ['feature-state', 'opacity'], 0],
  },
  minzoom: 18,
});

const buildPlantsBordersLayer = () => ({
  id: 'plantsBorders',
  type: 'line',
  source: 'plantsGeoJson',
  paint: {
    'line-color': '#000',
    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 1.3, 0.5],
  },
  minzoom: 18,
});

export {
  buildQuartersFillLayer,
  buildQuartersBordersLayer,
  buildQuartersLabelsLayer,
  buildQuarterRowsFillLayer,
  buildQuarterRowsBordersFillLayer,
  buildQuarterRowsLabelsLayer,
  buildPlantsFillLayer,
  buildPlantsBordersLayer,
};
