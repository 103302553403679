import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { Dropdown, Row, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { mapStyleSwitcherStyles } from './styles';

const DOMAIN = 'https://api.maptiler.com/maps/';
const MAPTILER_KEY = 'pPdlTOyMLr47W2mqoFrR';
const KEY_PARAM = `?key=${MAPTILER_KEY}`;
const STYLE_PATH = 'style.json';

const MAP_STYLES = [
  {
    url: `https://demotiles.maplibre.org/${STYLE_PATH}`,
    label: 'Demo Tiles',
  },
  {
    url: `${DOMAIN}satellite/${STYLE_PATH}${KEY_PARAM}`,
    label: 'Satellite',
  },
  {
    url: `${DOMAIN}basic-v2/${STYLE_PATH}${KEY_PARAM}`,
    label: 'Basic V2',
  },
  {
    url: `${DOMAIN}openstreetmap/${STYLE_PATH}${KEY_PARAM}`,
    label: 'Open Street Map',
  },
  {
    url: `${DOMAIN}streets-v2/${STYLE_PATH}${KEY_PARAM}`,
    label: 'Streets V2',
  },
  {
    url: `${DOMAIN}toner-v2/${STYLE_PATH}${KEY_PARAM}`,
    label: 'Toner V2',
  },
];

const defaultMapStyle = JSON.parse(localStorage.getItem('session-map-style')) || MAP_STYLES[0];
const items = MAP_STYLES.map(({ label }) => ({ label, key: label }));

const MapStyleSwitcher = ({ mapRef, setMapStyle }) => {
  const [mapStyleLabel, setMapStyleLabel] = useState(defaultMapStyle.label);

  const onClick = ({ key }) => {
    const newMapStyle = MAP_STYLES.find(({ label }) => label === key);

    localStorage.setItem('session-map-style', JSON.stringify(newMapStyle));
    mapRef.current.setStyle(newMapStyle.url, { diff: false });
    setMapStyleLabel(newMapStyle.label);
    setMapStyle(newMapStyle);
  };

  return (
    <Row className={mapStyleSwitcherStyles}>
      <Dropdown menu={{ items, onClick, selectable: true }}>
        <Typography.Link>
          <Space style={{ whiteSpace: 'nowrap' }}>
            {mapStyleLabel}
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </Row>
  );
};

MapStyleSwitcher.propTypes = {
  mapRef: shape({}).isRequired,
  setMapStyle: func,
};

MapStyleSwitcher.defaultProps = {
  setMapStyle: () => {},
};

export { defaultMapStyle };
export default MapStyleSwitcher;
