import React from 'react';
import { createRoot } from 'react-dom/client';
import { camelize } from 'humps';
import maplibregl from 'maplibre-gl';
import QuarterPopup from 'components/Map/components/QuarterPopup';
import QuarterRowPopup from 'components/Map/components/QuarterRowPopup';
import PlantPopup from 'components/Map/components/PlantPopup';
import { layerIds } from '.';

const FEATURE_TYPE_TO_POPUP_MAPPING = {
  quarter: QuarterPopup,
  quarterRow: QuarterRowPopup,
  plant: PlantPopup,
};

const featurePopup = new maplibregl.Popup({ closeButton: false });
const featurePopupNode = document.createElement('div');
const reactDomRootFeaturePopup = createRoot(featurePopupNode);

let hoveredFeature = null;

const buildFeatureHighlighter = (map) => (feature) => {
  if (!feature) return;

  if (hoveredFeature) {
    const { properties: { id, layer_id: layerId } } = hoveredFeature;
    map.setFeatureState({ id, source: layerId }, { hover: false });
  }

  hoveredFeature = feature;

  const { properties } = feature;
  const { id, layer_id: layerId, feature_type: featureType } = properties;
  const FeaturePopup = FEATURE_TYPE_TO_POPUP_MAPPING[camelize(featureType)];

  map.setFeatureState({ id, source: layerId }, { hover: true });
  map.getCanvas().style.cursor = 'pointer';

  if (FeaturePopup) {
    featurePopup.setLngLat(map.getCenter()).setDOMContent(featurePopupNode).addTo(map);
    featurePopup.addClassName('feature-popup');
    reactDomRootFeaturePopup.render(<FeaturePopup properties={properties} />);
  }
};

const buildFeatureUnhighlighter = (map) => () => {
  if (hoveredFeature) {
    const { properties: { id, layer_id: layerId } } = hoveredFeature;
    map.setFeatureState({ id, source: layerId }, { hover: false });
  }

  map.getCanvas().style.cursor = 'grab';
  featurePopup.remove();
  hoveredFeature = null;
};

const addHoverEffect = ({ map, isHoverEnabled }) => {
  if (!isHoverEnabled) return;

  const highlightFeature = buildFeatureHighlighter(map);
  const unhighlightFeature = buildFeatureUnhighlighter(map);

  layerIds.forEach((layerId) => {
    map.on('mousemove', layerId, ({ features }) => highlightFeature(features[0]));
    map.on('mouseleave', layerId, unhighlightFeature);
  });
};

export { addHoverEffect, buildFeatureHighlighter, buildFeatureUnhighlighter };
