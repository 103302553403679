import React, { useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { i18n } from 'helpers/i18n';

const { Option } = Select;

const UserForm = ({ user = null, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
  }, [user]);

  const handleFinish = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{
        first_name: user?.firstName || '',
        last_name: user?.lastName || '',
        email: user?.email || '',
        role: user?.role || 'worker',
      }}
    >
      <Form.Item
        name="first_name"
        label={i18n.t('staff.attributes.first_name')}
        rules={[{ required: true, message: i18n.t('staff.errors.first_name_required') }]}
      >
        <Input placeholder={i18n.t('staff.attributes.first_name')} />
      </Form.Item>
      <Form.Item
        name="last_name"
        label={i18n.t('staff.attributes.last_name')}
        rules={[{ required: true, message: i18n.t('staff.errors.last_name_required') }]}
      >
        <Input placeholder={i18n.t('staff.attributes.last_name')} />
      </Form.Item>
      <Form.Item
        name="email"
        label={i18n.t('staff.attributes.email')}
        rules={[
          { required: true, message: i18n.t('staff.errors.email_required') },
          { type: 'email', message: i18n.t('staff.errors.invalid_email') },
        ]}
      >
        <Input type="email" placeholder={i18n.t('staff.attributes.email')} />
      </Form.Item>
      <Form.Item
        name="role"
        label={i18n.t('staff.attributes.position')}
        rules={[{ required: true, message: i18n.t('staff.errors.role_required') }]}
      >
        <Select placeholder={i18n.t('staff.attributes.position')}>
          <Option value="worker">{i18n.t('staff.attributes.worker')}</Option>
          <Option value="supervisor">{i18n.t('staff.attributes.supervisor')}</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {user ? i18n.t('staff.actions.save') : i18n.t('staff.actions.add')}
        </Button>
        {onCancel && (
          <Button style={{ marginLeft: '10px' }} onClick={onCancel}>
            {i18n.t('staff.actions.cancel')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default UserForm;
