import React, { useRef, useState, useEffect, useMemo } from 'react';
import { string } from 'prop-types';
import {
  AutoComplete, Input, Row, Space, Typography, Table, Tag, Layout as AntdLayout, Spin,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { uniq, uniqBy, find, difference, isEmpty } from 'lodash';
import Layout from 'components/Layout';
import Map from 'components/Monitoring/Map';
import bbox from '@turf/bbox';
import { i18n } from 'helpers/i18n';
import {
  buildFeatureHighlighter, buildFeatureUnhighlighter,
} from 'components/Monitoring/Map/helpers/featureHighlight';
import { useRotateMap } from 'components/Monitoring/Map/hooks/useRotateMap';
import { useMapColorType } from 'components/Monitoring/Map/hooks/useMapColorType';
import { colorizeFeatures } from 'components/Monitoring/Map/helpers';
import { siderStyles, tableStyles, hoverableTableCell } from './styles';
import {
  tableRowsMonitoringIndexPath, quartersLayerMonitoringIndexPath,
  quarterRowsLayerMonitoringIndexPath, plantsLayerMonitoringIndexPath
} from 'js-routes';

const { Title } = Typography;
const { Content, Sider } = AntdLayout;

const filterValue = (value) => ({ text: value, value });
const stringSorterByAttribute = (attributeName) => (a, b) => (
  new Intl.Collator().compare(a[attributeName], b[attributeName])
);

const buildColumns = ({
  tableRows, highlightQuarter, highlightRow, unhighlightFeature, fitQuarterBounds, fitRowBounds, allLayersLoaded,
}) => [
    {
      title: i18n.t('activerecord.models.quarter_row'),
      dataIndex: 'name',
      render: (name, { id }) => (
        <Row
          className={hoverableTableCell}
          onClick={() => fitRowBounds(id)}
          onMouseEnter={() => highlightRow(id)}
          onMouseLeave={unhighlightFeature}
        >
          {name}
        </Row>
      ),
    },
    {
      title: i18n.t('activerecord.models.quarter'),
      dataIndex: 'quarter',
      onFilter: (value, record) => record.quarter === value,
      filters: allLayersLoaded
      ? uniq(tableRows.map(({ quarter }) => quarter)).map(filterValue)
      : null,
      render: (name, { quarter_id: quarterId }) => (
        <Row
          className={hoverableTableCell}
          onClick={() => fitQuarterBounds(quarterId)}
          onMouseEnter={() => highlightQuarter(quarterId)}
          onMouseLeave={unhighlightFeature}
        >
          {name}
        </Row>
      ),
    },
    {
      title: i18n.t('activerecord.models.cultivar'),
      dataIndex: 'cultivar',
      filters: allLayersLoaded
      ? uniq(tableRows.flatMap(({ cultivar }) => cultivar.map(({ name }) => name))).map(filterValue)
      : null,
      onFilter: (value, record) => record.cultivar.some(({ name }) => name === value),
      render: (cultivars) => (
        <Space direction="vertical">
          {cultivars.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>)}
        </Space>
      ),
    },
    {
      title: i18n.t('activerecord.models.crop', { count: 1 }),
      dataIndex: 'crop',
      render: (crops) => (
        <Space direction="vertical">
          {crops.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>)}
        </Space>
      ),
    },
    { title: i18n.t('activerecord.attributes.quarter_row.plants_count'), dataIndex: 'plantsCount' },
  ];

const getVisibleFeatures = ({ tableRows, quartersFeatures, rowsFeatures, plantsFeatures }) => {
  const visibleQuarterIds = tableRows.map(({ quarter_id: quarterId }) => quarterId);
  const visibleQuarterRowsIds = tableRows.map(({ id }) => id);
  const visibleQuartersFeatures = (
    quartersFeatures.filter(({ properties }) => visibleQuarterIds.includes(properties.id))
  );
  const visibleRowsFeatures = (
    rowsFeatures.filter(({ properties }) => visibleQuarterRowsIds.includes(properties.id))
  );

  return [...visibleQuartersFeatures, ...visibleRowsFeatures, ...plantsFeatures];
};

const Monitoring = ({ totalArea }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [geoJsonLayers, setGeoJsonLayers] = useState({
    quartersGeoJson: null,
    quarterRowsGeoJson: null,
    plantsGeoJson: null,
  });

  const updateQuartersGeoJson = (quartersRes) => {
    setGeoJsonLayers((prevState) => ({
      ...prevState,
      quartersGeoJson: { ...quartersRes, features: quartersRes?.features || [] },
    }));
  };

  const updateRowsGeoJson = (rowsRes) => {
    setGeoJsonLayers((prevState) => ({
      ...prevState,
      quarterRowsGeoJson: { ...rowsRes, features: rowsRes?.features || [] },
    }));
  };

  const updatePlantsGeoJson = (plantsRes) => {
    setGeoJsonLayers((prevState) => ({
      ...prevState,
      plantsGeoJson: { ...plantsRes, features: plantsRes?.features || [] },
    }));
  };

  const [rowsFeatures, setRowsFeatures] = useState([]);
  const rowsFeaturesRef = useRef([]);
  const updateRowsFeatures = (features) => {
    setRowsFeatures(features);
    rowsFeaturesRef.current = features;
  };
  const [quartersFeatures, setQuartersFeatures] = useState([]);
  const quartersFeaturesRef = useRef([]);
  const updateQuartersFeatures = (features) => {
    setQuartersFeatures(features);
    quartersFeaturesRef.current = features;
  };
  const [plantsFeatures, setPlantsFeatures] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [allLayersLoaded, setAllLayersLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { cancelRotateMap } = useRotateMap();
  const [features, setFeatures] = useState([]);
  const [mapStyle, setMapStyle] = useState();

  const { mapColorType, setMapColorType } = useMapColorType({
    mapInstance,
    features,
    mapStyle,
  });

  useEffect(() => {
    if (!mapInstance || quartersFeatures.length === 0 || rowsFeatures.length === 0) return;
    const visibleFeatures = getVisibleFeatures({ tableRows, quartersFeatures, rowsFeatures, plantsFeatures });
    setFeatures(visibleFeatures);
  }, [mapInstance, tableRows, geoJsonLayers]);

  useEffect(() => {
    if (mapInstance) {
      const fetchData = async () => {
        try {
          setTableLoading(true);

          const quartersPromise = fetch(quartersLayerMonitoringIndexPath()).then((response) => response.json());
          const rowsPromise = fetch(quarterRowsLayerMonitoringIndexPath()).then((response) => response.json());
          const plantsPromise = fetch(plantsLayerMonitoringIndexPath()).then((response) => response.json());
          const tablePromise = fetch(tableRowsMonitoringIndexPath()).then((response) => response.json());

          tablePromise.then((tableRes) => {
          setTableRows(tableRes || []);
          }).finally(() => {
            setTableLoading(false);
          });

          const handleQuartersResponse = quartersPromise.then((quartersRes) => {
            updateQuartersGeoJson(quartersRes);
            updateQuartersFeatures(quartersRes?.features || []);
          });

          const handleRowsResponse = rowsPromise.then((rowsRes) => {
            updateRowsGeoJson(rowsRes);
            updateRowsFeatures(rowsRes?.features || []);
          });

          const handlePlantsResponse = plantsPromise.then((plantsRes) => {
            updatePlantsGeoJson(plantsRes);
            setPlantsFeatures(plantsRes?.features || []);
          });


          await Promise.all([handleQuartersResponse, handleRowsResponse, handlePlantsResponse]);
        } catch (error) {
          console.error('Couldn`t fetch data:', error);
        }
      };

      fetchData();
    }
  }, [mapInstance]);

  const highlightFeature = buildFeatureHighlighter(mapInstance);
  const unhighlightFeature = buildFeatureUnhighlighter(mapInstance);
  const highlightQuarter = (id) => highlightFeature(find(quartersFeatures, { id }));
  const highlightRow = (id) => highlightFeature(find(rowsFeatures, { id }));
  const fitQuarterBounds = (id) => {
    const feature = find(quartersFeaturesRef.current, { id });
    const [minX, minY, maxX, maxY] = bbox(feature);

    cancelRotateMap();
    mapInstance.fitBounds([[minX, minY], [maxX, maxY]]);
  };
  const fitRowBounds = (id) => {
    const feature = find(rowsFeaturesRef.current, { id });
    const [minX, minY, maxX, maxY] = bbox(feature);

    cancelRotateMap();
    mapInstance.fitBounds([[minX, minY], [maxX, maxY]]);
  };

  const columns = useMemo(() => {
    if (!mapInstance) return [];

    return buildColumns({
      tableRows, highlightQuarter, highlightRow, unhighlightFeature, fitQuarterBounds, fitRowBounds, allLayersLoaded,
    });
  }, [mapInstance, tableRows, allLayersLoaded]);

  const handleTableChange = (p, f, s, { currentDataSource }) => {
    const allRowsIds = tableRows.map(({ id }) => id);
    const allQuartersIds = tableRows.map(({ quarter_id: quarterId }) => quarterId);
    const visibleRowsIds = currentDataSource.map(({ id }) => id);
    const hiddenRowsIds = difference(allRowsIds, visibleRowsIds);
    const visibleQuarterIds = currentDataSource.map(({ quarter_id: quarterId }) => quarterId);
    const hiddenQuartersIds = difference(allQuartersIds, visibleQuarterIds);
    const visibleQuartersFeatures = (
      quartersFeatures.filter(({ properties: { id } }) => visibleQuarterIds.includes(id))
    );
    const hiddenQuarters = (
      quartersFeatures.filter(({ properties: { id } }) => hiddenQuartersIds.includes(id))
    );
    const visibleQuarterRowsFeatures = (
      rowsFeatures.filter(({ properties: { id } }) => visibleRowsIds.includes(id))
    );
    const hiddenQuarterRows = (
      rowsFeatures.filter(({ properties: { id } }) => hiddenRowsIds.includes(id))
    );

    colorizeFeatures(visibleQuartersFeatures, mapInstance, mapColorType);
    colorizeFeatures(visibleQuarterRowsFeatures, mapInstance, mapColorType);
    colorizeFeatures(hiddenQuarters, mapInstance, mapColorType, { uncolorize: true });
    colorizeFeatures(hiddenQuarterRows, mapInstance, mapColorType, { uncolorize: true });
  };

  const buildOptions = () => {
    const quarters = (
      uniqBy(tableRows, 'quarter')
        .filter(({ quarter }) => quarter.toLowerCase().includes(searchText.toLowerCase()))
        .sort(stringSorterByAttribute('quarter'))
        .map(({ quarter, quarter_id: quarterId }) => ({
          label: (
            <div
              onMouseEnter={() => highlightQuarter(quarterId)}
              onClick={() => fitQuarterBounds(quarterId)}
            >
              {quarter}
            </div>
          ),
          value: quarter,
        }))
    );

    const rows = (
      uniqBy(tableRows, 'name')
        .filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()))
        .sort(stringSorterByAttribute('name'))
        .map(({ id, name }) => ({
          label: (
            <div
              onMouseEnter={() => highlightRow(id)}
              onClick={() => fitRowBounds(id)}
            >
              {name}
            </div>
          ),
          value: name,
        }))
    );

    return [
      !isEmpty(quarters) && ({ label: i18n.t('quarters'), options: quarters }),
      !isEmpty(rows) && ({ label: i18n.t('quarter_rows'), options: rows }),
    ].filter(Boolean);
  };

  return (
    <Layout fullWidth>
      <AntdLayout style={{ height: '100%' }}>
        <Content>
          <Map
            geoJsonLayers={geoJsonLayers}
            bounds={
              geoJsonLayers.quartersGeoJson &&
              geoJsonLayers.quartersGeoJson.features?.length > 0 &&
              bbox(geoJsonLayers.quartersGeoJson)
            }
            setMapInstance={setMapInstance}
            mapColorType={mapColorType}
            setMapColorType={setMapColorType}
            mapStyle={mapStyle}
            setMapStyle={setMapStyle}
            setAllLayersLoaded={setAllLayersLoaded}
            style={{ height: '100%' }}
          />
        </Content>
        <Sider className={siderStyles} width={600} trigger={null}>
          <Title level={2}>
            {i18n.t('my_orchard')}
            <sup>{totalArea}</sup>
          </Title>
          <AutoComplete
            options={buildOptions()}
            style={{ width: '100%', marginBottom: 20 }}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              placeholder={i18n.t('enter_quarter_or_row_name')}
              onChange={({ target }) => setSearchText(target.value)}
              suffix={<SearchOutlined style={{ color: '#999' }} />}
              allowClear
            />
          </AutoComplete>
          <Table
            className={tableStyles}
            columns={columns}
            dataSource={tableRows}
            loading={tableLoading}
            onChange={handleTableChange}
            scroll={{ x: 700, y: 'calc(100vh - 295px)' }}
          />
        </Sider>
      </AntdLayout>
    </Layout>
  );
};

Monitoring.propTypes = {
  totalArea: string.isRequired,
};

export default Monitoring;
