import { css } from '@emotion/css';

const mapColoringSwitcherStyles = css`
  z-index: 1;
  width: fit-content;
  background-color: #fff;
  border-radius: 10px;
`;

export { mapColoringSwitcherStyles };
