import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, bool, string, number, shape, oneOfType, func } from 'prop-types';
import { Row } from 'antd';
import maplibregl from 'maplibre-gl';
import bbox from '@turf/bbox';
import { countryBounds } from 'helpers/userSession';
import MapStyleSwitcher, { defaultMapStyle } from 'components/Map/components/MapStyleSwitcher';
import MapColoringSwitcher from 'components/Map/components/MapColoringSwitcher';
import { useRotateMap } from './hooks/useRotateMap';
import { outerContainerStyles, innerContainerStyles, controlsContainerStyles } from './styles';
import { addHoverEffect } from './helpers/featureHighlight';
import {
  buildQuartersFillLayer,
  buildQuartersBordersLayer,
  buildQuartersLabelsLayer,
  buildQuarterRowsFillLayer,
  buildQuarterRowsBordersFillLayer,
  buildQuarterRowsLabelsLayer,
  buildPlantsFillLayer,
  buildPlantsBordersLayer,
} from './layers';
import {
  addSources,
  addLayers,
  addLayerWithOrder,
  addClickHandler,
  buildCircles,
  buildSources,
} from './helpers';
import { useMapColorType } from './hooks/useMapColorType';

const mapContainerId = 'mapContainerId';

const Map = ({
  geoJsonLayers,
  bounds,
  isHoverEnabled,
  isClickEnabled,
  setMapInstance,
  mapColorType,
  setMapColorType,
  mapStyle,
  setMapStyle,
  setAllLayersLoaded,
  mapRef: mapRefFromProps,
  style: { height = '400px' },
}) => {
  const innerMapRef = useRef(null);
  const mapRef = mapRefFromProps || innerMapRef;
  const geoJsonLayersRef = useRef(geoJsonLayers);
  const { quartersGeoJson, quarterRowsGeoJson, plantsGeoJson } = geoJsonLayers;
  const { rotateMap, cancelRotateMap } = useRotateMap();
  const layerOrder = ['quartersGeoJson', 'quartersBorders',
    'quarterRowsGeoJson', 'quarterRowsBorders',
    'plantsGeoJson', 'plantsBorders',
    'quartersGeoJsonLabels', 'quarterRowsGeoJsonLabels',
  ];

  const [layersLoaded, setLayersLoaded] = useState({
    quarters: false,
    quarterRows: false,
    plants: false,
  });

  useEffect(() => {
    const allLayersLoaded = Object.values(layersLoaded).every((loaded) => loaded);
    if (allLayersLoaded) {
      setAllLayersLoaded(true);
    }
  }, [layersLoaded]);

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerId,
      bounds: bounds || countryBounds,
      style: defaultMapStyle.url,
      pitch: 50,
      attributionControl: false,
    });

    map.on('load', () => {
      addHoverEffect({ map, isHoverEnabled });
      addClickHandler({ map, isClickEnabled });

      setMapInstance(map);
    });

    map.on('mousedown', cancelRotateMap);

    map.addControl(new maplibregl.NavigationControl());
    map.addControl(new maplibregl.ScaleControl());

    mapRef.current = map;

    return () => mapRef.current.remove();
  }, []);

  const addLayerGroup = (map, layerConfig) => {
    const { sources, layers, layerBounds, onAdd } = layerConfig;

    const addLayersInOrder = () => {
      if (sources) addSources(map, sources);
      layers.forEach(({ buildLayer }) => {
        addLayerWithOrder(map, buildLayer(), layerOrder);
      });
      if (layerBounds) map.fitBounds(layerBounds, { padding: 20 });
      if (onAdd) onAdd();
    };

    if (map?.style?._loaded) {
      addLayersInOrder();
    } else {
      const onStyleLoad = () => {
        addLayersInOrder();
        map.off('style.load', onStyleLoad);
      };
      map.on('style.load', onStyleLoad);
    }
  };

  useEffect(() => {
    if (!mapRef.current || !quartersGeoJson) return;

    const map = mapRef.current;

    addLayerGroup(map, {
      sources: buildSources({ quartersGeoJson }),
      layers: [
        { buildLayer: buildQuartersFillLayer, layerOrder },
        { buildLayer: buildQuartersBordersLayer, layerOrder },
        { buildLayer: buildQuartersLabelsLayer, layerOrder },
      ],
      layerBounds: quartersGeoJson.features?.length > 0 ? bbox(quartersGeoJson) : null,
      onAdd: () => setLayersLoaded((prev) => ({ ...prev, quarters: true })),
    });
  }, [quartersGeoJson]);

  useEffect(() => {
    if (!mapRef.current || !quarterRowsGeoJson) return;

    const map = mapRef.current;

    addLayerGroup(map, {
      sources: buildSources({ quarterRowsGeoJson }),
      layers: [
        { buildLayer: buildQuarterRowsFillLayer, layerOrder },
        { buildLayer: buildQuarterRowsBordersFillLayer, layerOrder },
        { buildLayer: buildQuarterRowsLabelsLayer, layerOrder },
      ],
      bounds: quarterRowsGeoJson.features?.length > 0 ? bbox(quarterRowsGeoJson) : null,
      onAdd: () => setLayersLoaded((prev) => ({ ...prev, quarterRows: true })),
    });
  }, [quarterRowsGeoJson]);

  useEffect(() => {
    if (!mapRef.current || !plantsGeoJson) return;

    const map = mapRef.current;

    addLayerGroup(map, {
      sources: buildSources({ plantsGeoJson: buildCircles(plantsGeoJson) }),
      layers: [
        { buildLayer: buildPlantsFillLayer, layerOrder },
        { buildLayer: buildPlantsBordersLayer, layerOrder },
      ],
      onAdd: () => setLayersLoaded((prev) => ({ ...prev, plants: true })),
    });
  }, [plantsGeoJson]);

  useEffect(() => {
    geoJsonLayersRef.current = {
      ...geoJsonLayersRef.current,
      quartersGeoJson,
    };
  }, [quartersGeoJson]);

  useEffect(() => {
    geoJsonLayersRef.current = {
      ...geoJsonLayersRef.current,
      quarterRowsGeoJson,
    };
  }, [quarterRowsGeoJson]);

  useEffect(() => {
    geoJsonLayersRef.current = {
      ...geoJsonLayersRef.current,
      plantsGeoJson,
    };
  }, [plantsGeoJson]);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current;

    const addDataToMap = () => {
      addSources(map, buildSources({
        quartersGeoJson: geoJsonLayersRef.current.quartersGeoJson,
        quarterRowsGeoJson: geoJsonLayersRef.current.quarterRowsGeoJson,
        plantsGeoJson: buildCircles(geoJsonLayersRef.current.plantsGeoJson),
      }));

      addLayers(map, [
        buildQuartersFillLayer(),
        buildQuartersBordersLayer(),
        buildQuarterRowsFillLayer(),
        buildQuarterRowsBordersFillLayer(),
        buildPlantsFillLayer(),
        buildPlantsBordersLayer(),
        buildQuartersLabelsLayer(),
        buildQuarterRowsLabelsLayer(),
      ]);

      if (quartersGeoJson && quartersGeoJson.features?.length > 0) {
        bounds = bbox(quartersGeoJson);
        map.fitBounds(bounds, { padding: 20 });
      }
    };

    if (map?.style?._loaded) {
      addDataToMap();
    } else {
      const onStyleLoad = () => {
        addDataToMap();
        map.off('style.load', onStyleLoad);
      };

      map.on('style.load', onStyleLoad);
    }
  }, [mapStyle]);

  return (
    <Row className={outerContainerStyles({ height })}>
      <div id={mapContainerId} className={innerContainerStyles} />
      <div className={controlsContainerStyles}>
        {mapColorType && (
          <MapColoringSwitcher mapColorType={mapColorType} setMapColorType={setMapColorType} />)}
        <MapStyleSwitcher mapRef={mapRef} setMapStyle={setMapStyle} />
      </div>
    </Row>
  );
};

Map.propTypes = {
  mapRef: shape({ current: shape({}) }),
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
    quarterRowsGeoJson: shape({}),
    plantsGeoJson: shape({}),
  }),
  bounds: arrayOf(oneOfType([arrayOf(number), number])),
  isHoverEnabled: bool,
  isClickEnabled: bool,
  setMapInstance: func,
  setAllLayersLoaded: func,
  style: shape({}),
  mapColorType: string,
  setMapColorType: func,
  mapStyle: string,
  setMapStyle: func,
};

Map.defaultProps = {
  mapRef: null,
  geoJsonLayers: {},
  bounds: null,
  isHoverEnabled: true,
  isClickEnabled: true,
  setMapInstance: () => {},
  setAllLayersLoaded: () => {},
  style: {},
  mapColorType: null,
  setMapColorType: () => {},
  mapStyle: null,
  setMapStyle: () => {},
};


export default Map;
