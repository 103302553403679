import { css } from '@emotion/css';

const mapStyleSwitcherStyles = css`
  z-index: 1;
  width: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 1px 8px;
`;

export { mapStyleSwitcherStyles };
